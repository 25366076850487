/** @format */

import { DateRange } from '@angular/material/datepicker';
import { MatDialogConfig } from '@angular/material/dialog';
import { EventColor } from 'calendar-utils';
import {
  addDays,
  addMilliseconds,
  addMinutes,
  differenceInMinutes,
  isEqual,
  roundToNearestMinutes,
  set,
  startOfDay,
} from 'date-fns';
import {
  CalendarEventData,
  CalendarEventDialogComponent,
} from 'src/app/dialogs/calendar-event/calendar-event.component';
import { ArrayUtil } from 'src/app/shared/array-util';
import { ColorUtil } from 'src/app/shared/color.util';
import { NIL as EMPTY_GUID } from 'uuid';
import { DateUtil } from '../../shared/date-util';
import { Rest } from '../../shared/rest';
import { TimeUtil } from '../../shared/time-util';
import { CommonBase, CommonView, normalizeCommonBase, removeCommonBase } from '../common.service';
import { LazyDialogService } from '../lazy-dialog.service';
import { StateService } from '../state.service';
import { CalendarAttendanceMap, CalendarAttendanceType } from './calendar-attendance.service';

const urlCollection = {
  url: (id: number) => `Organizations/${StateService.user?.OrganizationId}/Calendar`,
  // url: () => `Organizations/${StateService.user?.OrganizationId}/Calendar/AppointmentList`,
  urlList: (profileId: string) =>
    `Organizations/${StateService.user?.OrganizationId}/Calendar/Appointments/${profileId || EMPTY_GUID}`,
  urlGet: (id: number) => `Organizations/${StateService.user?.OrganizationId}/Calendar/Appointment/${id}`,
  // urlGet: (id: number) => `Organizations/${StateService.user?.OrganizationId}/Calendar/Appointment/${id}/View`,
  cancel: {
    url: () => '',
    urlPut: (id: number) => `Organizations/${StateService.user?.OrganizationId}/Calendar/${id}/CancelAppointment`,
  },
};

// #region TYPE DEFINITIONS

type CalendarAttendeeType = 'Client' | 'Staff/Volunteer';

export interface CalendarAttendee extends CommonBase {
  AppointmentId: number;
  AttendanceTypeId: number;
  ContextId: string; //  client:clientId, staff:userId "a98d9bc3-3a0b-48c2-a57e-60d5dd98bd1c"
  PersonAppointmentId: number;
  PersonId: string;
  // readonly
  AllowTextContact?: number; // bit
  ContextType?: CalendarAttendeeType; // "Client"
  FirstName?: string;
  LastName?: string;
  MobileIntakeSMSStatus?: null;
  MobileNo?: string;
  state?: CalendarAttendanceType;
}

export interface CalendarAppointment extends CommonBase {
  AppointmentId: number; // 95132
  AppointmentOldIdRescheduled: number;
  AppointmentRequestedClientId: string;
  AppointmentRequestId: number;
  AppointmentRequestSourceCode: string;
  AppointmentRescheduledClientId: string;
  AppointmentTypeId: number;
  AppointmentWithReminderSentCount: string;
  Attendees: CalendarAttendee[];
  CampaignId: number;
  ConfirmedAppointments: string;
  Description: string;
  FollowUpOccurred: boolean;
  IsAllDay: boolean;
  IsNotAvailable: boolean;
  IsPhoneCall: boolean;
  IsTelehealthAppt: boolean;
  IsRepeat: boolean;
  IsRequest: boolean; // is pending apt request
  LocationId: number;
  LocationResourceId: number;
  OLAppointmentId: number;
  RoomId: string; // "3181264C961218C704B85B82AC7B60C9"
  ServiceUnitId: number;
  ServiceUnitItemId: number;
  ServiceUnitTypeId: number;
  SMSStatus: string; // "sent"
  StartDate: string; // "12/04/2023 9:00:00 AM"
  StopDate: string; // "12/04/2023 10:00:00 AM"
  Title: string;
  TotalAppointments: string; // "1"
  Value: number;
  appointmentAttendanceType: string; // Scheduled, Attended, Cancelled/Did not reschedule, Rescheduled
  // readonly
  EndTime: string; // "12:00pm"
  LocationDisplay: string; // "Heartbeat International"
  LocationResourceDisplay: string; // "General Purpose Room 1"
  ReasonText: string; // based on ServiceUnitTypeId
  reasonTypeColor: string; // "#86f465"
  serviceUnitTypeText: string;
  StartDateDisplay: string; // "03/03/2022"
  StartTime: string; // "10:00am"
  StopDateDisplay: string; // "03/03/2022"
  // comes from appointment requests (needed for time normalization)
  EndDate: string; // "12/18/2023 12:00:00 AM"
  // mapped
  color: EventColor;
  confirmedAppointments: number;
  duration: number; // in minutes
  startEpoc: number; // StartDate.valueOf()
  startDate: Date; // StartDate
  stopEpoc: number; // StopDate.valueOf()
  stopDate: Date; // StopDate
  state: CalendarAttendanceType; // appointmentAttendanceType (requested, scheduled, attended, cancelled, rescheduled)
  totalAppointments: number;
}

type CalendarAppointmentView = CommonView<CalendarAppointment>;

export interface CalendarFilter {
  locationId?: number;
  profileId?: string;
  maxCount?: number;
  attended?: boolean;
  reasonId?: number; // serviceUnitTypeId
  contextType?: 'Client' | 'DONOR' | 'CLIENT/DONOR';
  personId?: string; // not sure why this is an option
}

// #endregion

// #region PRIVATE

const calcState = (item: CalendarAppointment) => {
  return item.Attendees.reduce((result: CalendarAttendanceType, attendee) => {
    if (attendee.ContextType === 'Staff/Volunteer') return result;
    const attendanceType = CalendarAttendanceMap[attendee.AttendanceTypeId];
    if (!result || result === attendanceType) return attendanceType;
    return 'mixed';
  }, null);
};

const normalizeNumber = (number: string): number => {
  return number ? parseInt(number, 10) : 0;
};

export const fixEventDates = (item: CalendarAppointment) => {
  Object.assign(item, {
    startDate: DateUtil.parseDate(item.StartDate),
    stopDate: DateUtil.parseDate(item.StopDate || item.EndDate),
    StartTime: TimeUtil.to24hr(item.StartTime),
    EndTime: TimeUtil.to24hr(item.EndTime),
  });
  // set time on date since appointment requests are always 12am
  const [startHr, startMin] = item.StartTime.split(':').map((x) => parseInt(x));
  const [stopHr, stopMin] = item.EndTime.split(':').map((x) => parseInt(x));
  // correct server fudging dates this way and that way for some unknown reason
  // forces events to start/stop on 5 minute time increments 12:00, 12:05, 12:10, etc...
  // if stop hour is 0, it must be midnight
  Object.assign(item, {
    startDate: roundToNearestMinutes(set(item.startDate, { hours: startHr, minutes: startMin }), { nearestTo: 5 }),
    stopDate: roundToNearestMinutes(set(item.stopDate, { hours: stopHr || 24, minutes: stopMin }), { nearestTo: 5 }),
  });
  // end before start???
  if (item.startDate > item.stopDate) {
    // swap start/stop values
    Object.assign(item, {
      startDate: item.stopDate,
      stopDate: item.startDate,
      StartTime: item.EndTime,
      EndTime: item.StartTime,
    });
  }
  // event too short
  if (item.startDate === item.stopDate) {
    item.stopDate = addMinutes(item.startDate, 15);
  }
  // prevent appointments from appearing on previous day if starts at midnight
  if (item.AppointmentId && isEqual(item.startDate, startOfDay(item.startDate))) {
    item.startDate = addMilliseconds(item.startDate, 1);
  }
  // prevent appointments from appearing on next day if ends at midnight
  if (item.AppointmentId && isEqual(item.stopDate, startOfDay(item.stopDate))) {
    item.stopDate = addMilliseconds(item.stopDate, -1);
  }
  Object.assign(item, {
    startEpoc: item.startDate.valueOf(),
    stopEpoc: item.stopDate.valueOf(),
    duration: differenceInMinutes(item.stopDate, item.startDate, { roundingMethod: 'ceil' }),
  });
  return item;
};

const fixRequested = (item: CalendarAppointment) =>
  Object.assign(item, {
    // some services incorrectly set AppointmentId and AppointmentRequestId
    // e.g. /Appointments/{guid}
    AppointmentId: item.IsRequest ? null : item.AppointmentId,
    AppointmentRequestId: item.IsRequest ? item.AppointmentId : item.AppointmentRequestId,
    // some services don't set IsRequest (e.g. /AppointmentRequestById)
    // set if AppointmentRequestId provided
    IsRequest: item.AppointmentRequestId ? true : item.IsRequest,
  });

const getEventColor = (item: CalendarAppointment): EventColor => {
  if (item.IsRequest)
    return {
      primary: 'rgb(var(--color-primary))',
      secondary: 'transparent',
    };
  if (StateService.user?.isClient)
    return {
      primary: '#fff',
      secondary: 'rgb(var(--color-primary))',
    };
  return {
    primary: item.reasonTypeColor ? ColorUtil.invertColor(item.reasonTypeColor, true) : '#000',
    secondary: item.reasonTypeColor || 'rgb(var(--color-hope))', // #84B4E0 existing default from cms
  };
};

export const normalizeAppointment = (item: CalendarAppointment): CalendarAppointment =>
  Object.assign(normalizeCommonBase(item), fixEventDates(item), fixRequested(item), {
    Attendees: item.Attendees.map((attendee) => ({
      ...attendee,
      ContextType: attendee.ContextType || 'Client',
      state: CalendarAttendanceMap[attendee.AttendanceTypeId],
    }))?.sort(
      (a, b) =>
        b.ContextType.localeCompare(a.ContextType) ||
        a.FirstName.localeCompare(b.FirstName) ||
        a.LastName.localeCompare(b.LastName)
    ),
    color: getEventColor(item),
    // confirmed client count
    confirmedAppointments: normalizeNumber(item.ConfirmedAppointments),
    // only applies to list service and appears to be last attendance change made
    state: item.IsRequest ? 'requested' : calcState(item),
    // assigned client count
    totalAppointments: normalizeNumber(item.TotalAppointments),
  });

const normalizeAppointmentView = (item: CalendarAppointmentView): CalendarAppointment =>
  normalizeAppointment(item.viewModel);

const normalizeList = (items: CalendarAppointment[]): CalendarAppointment[] =>
  items
    .map(normalizeAppointment)
    .sort(
      (a, b) =>
        ArrayUtil.compare(a.startEpoc, b.startEpoc) ||
        ArrayUtil.compare(a.stopEpoc, b.stopEpoc) ||
        ArrayUtil.compare(a.Title, b.Title)
    );

const normalizeOut = (item: CalendarAppointment): CalendarAppointment =>
  Object.assign(removeCommonBase(item), {
    StartDate: DateUtil.formatDateTimeEN(item.startDate),
    StopDate: DateUtil.formatDateTimeEN(item.stopDate),
    // TODO: these should all be calculated or not used at all
    StartDateDisplay: DateUtil.formatDateEN(item.startDate),
    StopDateDisplay: DateUtil.formatDateEN(item.stopDate),
    StartTime: DateUtil.formatTimeEN(item.startDate).replace(/:\d\d /, ''),
    EndTime: DateUtil.formatTimeEN(item.stopDate).replace(/:\d\d /, ''),
    // cleanup
    color: undefined,
    confirmedAppointments: undefined,
    duration: undefined,
    startDate: undefined,
    startEpoc: undefined,
    state: undefined,
    stopDate: undefined,
    totalAppointments: undefined,
  });

// #endregion

export class CalendarAppointmentService {
  // @AuthRequire TODO: service does not enforce
  static readonly list = async (
    { start, end }: DateRange<number>,
    { locationId, profileId, maxCount, attended, reasonId, contextType, personId }: CalendarFilter
  ): Promise<CalendarAppointment[]> =>
    Rest.get<CalendarAppointment[]>(urlCollection, [profileId], {
      normalize: normalizeList,
      params: {
        // id: profileId || EMPTY_GUID,
        // dynamically add params if they are requested
        ...(start ? { sdate: DateUtil.formatDateEN(start || DateUtil.minDate) || '' } : {}),
        ...(end ? { edate: DateUtil.formatDateEN(end ? addDays(end, 1) : DateUtil.maxDate) || '' } : {}), // add day since service does not return events for the end date
        ...(attended != null ? { att: attended ? 'y' : 'n' } : {}),
        ...(contextType != null ? { ct: contextType } : {}),
        ...(personId != null ? { epid: personId } : {}),
        ...(locationId != null ? { location: locationId } : {}),
        ...(maxCount != null ? { number: maxCount } : {}),
        ...(reasonId != null ? { reason: reasonId } : {}),
      },
    });

  // @AuthRequire TODO: service does not enforce
  static readonly get = async (id: number): Promise<CalendarAppointment> =>
    Rest.get<CalendarAppointment, CalendarAppointment>(urlCollection, [], id, normalizeAppointment);

  // @AuthRequire TODO: service does not enforce
  static readonly update = async (data: CalendarAppointment, notify: boolean): Promise<void> =>
    Rest.putPost<void>(urlCollection, [], normalizeOut(data), data.AppointmentId, {
      params: { isNotificationAllowed: notify || false },
    });

  // @AuthRequire TODO: service does not enforce
  static readonly cancel = async (data: CalendarAppointment, notify: boolean): Promise<void> =>
    Rest.putPost<void>(urlCollection.cancel, [], {}, data.AppointmentId, {
      params: { isNotificationAllowed: notify || false },
    });

  // @AuthRequire TODO: service does not enforce
  static readonly delete = async (data: CalendarAppointment, notify: boolean): Promise<void> =>
    Rest.delete<void>(urlCollection, [], data.AppointmentId, {
      params: { isNotificationAllowed: notify || false },
    });

  static async open(event: number | CalendarAppointment | Promise<CalendarAppointment>) {
    const data: CalendarEventData = typeof event === 'number' ? { appointmentId: event } : { appointment: event };
    const dialogConfig = new MatDialogConfig<CalendarEventData>();
    Object.assign(dialogConfig, {
      autoFocus: false,
      closeOnNavigation: true,
      disableClose: true,
      data,
    });
    return LazyDialogService.open<unknown, any, CalendarEventDialogComponent>('calendar-event', dialogConfig);
  }
}
